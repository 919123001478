<template>
  <div>
<!--    <h5 class="info-text">{{ $t('transfer_userchoose') }}</h5>-->

    <div class="row justify-content-center">

      <div class="col-lg-10">
        <div class="row">
          <div class="col-12">
            <label> {{ $t('transfer_userchoose') }} </label>
            <fg-input type="email" placeholder="email@domain.com" v-model="email" v-on:keyup="recipientHandler"></fg-input>
            <button type="button" class="btn btn-info btn-fill btn-wd pull-right" v-on:click="preSearchUser" :disabled="searchu == false">
              {{ $t('searchtxt') }}
            </button>
          </div>
        </div>

        <div class="row" v-if="!isbroker && brokers.length">
          <div class="col-12">
            <h5 class="info-text">{{ $t('transfer_brokerchoose') }}</h5>
          </div>
        </div>

        <div class="row" v-if="!isbroker && brokers.length">
          <div :class="this.offset"></div>
          <div class="col-md-2" v-for="broker in brokers">
            <icon-checkbox-img
              v-model="broker.chosen"
              :icon="broker.icon"
              :title="broker.name"
              :small="true"
              @input="brokerChosen"
            ></icon-checkbox-img>
          </div>
        </div>

        <div class="row" v-if="model.user">
          <div class="col-12 text-center">
            <p class="lead" v-if="chosenBroker === null">
              <small>{{ $t('transfer_recipient_confirm') }}</small>
            </p>
            <p class="lead" v-if="chosenBroker !== null">
              <small>{{ $t('transfer_broker_confirm') }}</small>
            </p>
<!--            <span v-if="chosenBroker === null">-->
<!--              <i class="fa fa-user-circle-o 2x"></i> {{ model.user.first_name }} {{model.user.last_name}}-->
<!--            </span>-->
          </div>
        </div>

        <div class="alert alert-info col-6"
             v-if="model.user && model.user.transfer_limit_minimum > 0">
          <a class="info">
            <i class="fa fa-info-circle"> </i>
            <b> {{$t('transfer_limit_minimum', [model.user.transfer_limit_minimum])}}</b>
          </a>
        </div>

        <div class="row" v-if="model.user">
          <div class="col-3">
            <fg-input :label="transfervaluelabel" type="text" v-model="model.transfer_val"></fg-input>
          </div>

          <div class="col-3">
            <span v-if="chosenBroker === null">
              <label>{{ $t('totxt') }}</label>
              <fg-input addon-left-icon="fa fa-user-circle-o" disabled type="text" v-model="searchedUser"></fg-input>
            </span>
          </div>
        </div>

        <div class="row form-group" v-if="model.user">
          <div class="col-6">
            <label>{{ $t('description') }}</label>
            <textarea v-model="model.transfer_notes" id="description" class="form-control" :placeholder="transfernotesopt" rows="3"></textarea>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>
<script>
  import {IconCheckboxImg} from 'src/components/UIComponents'
  import Constants from 'src/assets/constants'
  import swal from 'sweetalert2'

  const user_data = JSON.parse(localStorage.getItem('user-info'));

  const rules = Constants.methods.getRules()

  export default {
    components: {
      IconCheckboxImg
    },
    data() {
      return {
        ukranianHelpTxt: 'Ukrainian Help',
        isWlSession: rules.isWhitelabelSession,
        brokers: [],
        chosenBroker: null,
        isbroker: false,
        model: {
          user: null,
          transfer_val: 0,
          transfer_notes: '',
          broker_deposit: false,
          broker_data: null
        },
        email: null,
        searchu: false,
        transfervaluelabel: this.$t('transfer_value'),
        uwalletbalance: null,
        transfernotesopt: this.$t('transfernotesopt'),
        offset: 'col-md-3',
        reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
      }
    },
    computed: {
      searchedUser() {
        return this.model.user.first_name + " " + this.model.user.last_name
      }
    },
    methods: {
      cleanit(val) {
        if(typeof num1 !== 'number') {
          val = String(val);
        }
        val = val.split(" ");
        val = val[0];
        //console.log(val);
        val = val.replace(/USD/g, "");
        val = val.replace(/\$/g, "");
        val = val.replace(',','.')
        //console.log(val);
        if (val == '') {
          val = 0;
        }
        //console.log(val);
        val = parseFloat(val);
        //console.log(val);
        return val;
      },
      brokerChosen(e) {
        this.model.user = null;
        let eml = null;
        let gotChosen = false;
        if (e && this.chosenBroker !== null) {
          let bidx = this.brokers.findIndex(b => b.email == this.chosenBroker.email);
          if (bidx >= 0) {
            this.brokers[bidx].chosen = false;
          }
        }
        for (var i = 0;i < this.brokers.length;i++) {
          let broker = this.brokers[i];
          if (broker.chosen) {
            eml = broker.email;
            if(broker.name === this.ukranianHelpTxt) {
              this.model.transfer_notes = this.ukranianHelpTxt;
              broker['donation'] = true;
            } else {
              this.model.transfer_notes = '';
              broker['donation'] = false;
            }
            this.chosenBroker = broker;
            gotChosen = true;
          }
        }

        if (eml !== null) {
          this.model.broker_deposit = true;
          this.model.broker_data = this.chosenBroker;
          // Modify description text
          this.transfernotesopt = this.$t('transfernotesman');
          this.email = null;
          this.findUser(eml);
        }

        if (!gotChosen) {
          this.model.user = null;
          this.model.broker_data = null;
          this.chosenBroker = null;
        }
      },
      isEmailValid() {
        return (this.email == "")? "" : this.reg.test(this.email);
      },
      pickedUser(user) {
        this.model.user = user.value;
      },
      recipientHandler(event) {
        const eml = this.isEmailValid();
        this.searchu = false;

        if (eml) {
          if (this.email.length > 0) {
            this.searchu = true;
          }
        }
      },
      preSearchUser() {
        this.model.user = null;
        this.model.broker_data = null;
        this.model.broker_deposit = false;
        this.chosenBroker = null;
        this.transfernotesopt = this.$t('transfernotesopt');
        this.cleanSelectBroker()
        this.findUser(this.email)
      },
      cleanSelectBroker(){
        for (let i = 0;i < this.brokers.length;i++) {
          this.brokers[i].chosen = false;
        }
      },
      findUser(resp){
        this.$checkIfUserExists(resp).then(this.searchUser, this.userNotfound)
      },
      searchUser(resp) {
        if(resp.success){
          this.model.user = resp.data;
        } else {
          this.userNotfound()
        }
      },
      userNotfound() {
        this.model.user = null;
        swal(this.$t('attentiontxt'), this.$t('useridnotfound'), 'warning')
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      validate() {
        return this.$validator.validateAll().then(res => {
          this.model.transfer_val = this.cleanit(this.model.transfer_val);

          if (this.model.transfer_val < this.model.user.transfer_limit_minimum) {
            swal(this.$t('attentiontxt'), this.$t('transfer_limit_minimum', [this.model.user.transfer_limit_minimum]), 'warning')
          }
          else if (this.model.user === null) {
            swal(this.$t('attentiontxt'), this.$t('transfer_valueconfirm'), 'warning')
          }
          else if (this.chosenBroker && (this.model.transfer_notes == '' || this.model.transfer_notes.length == 0)) {
            swal(this.$t('attentiontxt'), this.$t('transfer_broker_confirm_comment'), 'warning')
          }
          else if (this.model.transfer_val > this.uwalletbalance || this.model.transfer_val <= 0 || this.model.transfer_val === null || this.model.transfer_val === undefined || this.model.transfer_val === "" || this.model.transfer_val === 'NaN') {
            swal(this.$t('attentiontxt'), this.$t('transfer_valueconfirm'), 'warning')
          } else {
            this.$emit('on-validated', res, this.model);
            return res
          }
        })
      }
    },
    mounted() {
      const failop = error => {
        console.log(error);
      };
      const hiddenError = error => {

      };
      const loadedWallet = resp => {
        if (!resp.success) {
          // TODO ADD WARNING
          // TODO WARN:LOAD:PROJECTIONS
          return;
        }
        let data = resp.data;
        this.uwalletbalance = parseFloat(data.balance);
      };
      if (user_data['roles'].indexOf('broker') >= 0) {
        this.isbroker = true;
      }
      // Get VPSes for user
      this.$getWalletProjection(user_data.email)
        .then(loadedWallet, this.failop);

      this.$nextTick(() => {
        this.$getWalletBrokersTransfer()
          .then(resp => {
            if (!resp.success) {
              hiddenError(resp)
              return;
            }
            this.brokers = resp.data;
            this.offset = 'col-md-' + parseInt((12-(resp.data.length*2)) / 2);
          }, hiddenError)
      });
    }
  }
</script>
<style lang="scss">

</style>
